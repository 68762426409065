import React from "react"
import notfound from "../images/404.svg"
import Layout from "../components/Layout"

export default function NotFound() {
  return (
    <Layout>
      <div className="hero-image-wrap heading-2 container">
        <h2 className="heading-2">Page not found</h2>
        <br />
        <img width="50%" src={notfound} alt="" className="hero-image" />
      </div>
    </Layout>
  )
}
